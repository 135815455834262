<template>
    <div class="margin-bottom-50">
        <InsuranceContent
            :dataList="dataList"
            :isLoading="isLoading"
            class="ins-content"
            v-model="isShowEditModel"
        />
        <div class="viewtitle">保單商品</div>
        <Table
            v-if="tableShowData.length > 0 || isLoading"
            :isLoading="isLoading"
            :columnConfig="columnConfig"
            :rows="tableShowData"
        >
            <template v-slot:productType="{ row }">
                <div :class="`${row.productType}-product-icon`" />
            </template>
        </Table>
    </div>
</template>

<script>
import Table from '@/components/Table.vue'
import { insuranceProductColumns } from '@/assets/javascripts/tia/setting'
import InsuranceContent from '@/containers/tia/InsuranceContent.vue'

export default {
    name: 'InsuranceProduct',
    emits: ['update:modelValue'],
    components: {
        Table,
        InsuranceContent
    },
    props: {
        modelValue: {
            type: Object,
            default: function () {
                return { isShow: false }
            }
        },
        dataList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: function () {
                return false
            }
        }
    },
    computed: {
        tableShowData: function () {
            if (!this.dataList.insurance) return []
            // 主約

            const mainProduct = this.dataList.insurance.mainProducts.map(
                (product) => {
                    product.productType = 'main'
                    // TODO 後端把單位拆開後直接帶入就好

                    product.insAmount = product.insAmount
                        ? {
                              value: this.$numberWithComma(product.insAmount),
                              unit: product.insAmountUnit
                          }
                        : '-'

                    product.insPeriod = product.insPeriod
                        ? { value: product.insPeriod, unit: '年' }
                        : '-'
                    product.rfyp = {
                        value: product.rfyp,
                        unit: product.rfypUnit
                    }
                    product.bRate = {
                        value: product.bRate?.replace('%', ''),
                        unit: '%'
                    }
                    product.fyb = {
                        value: product.fyb,
                        unit: product.fybUnit
                    }
                    product.isVoid = product.isVoid
                        ? '是'
                        : product.isVoid === false
                        ? '否'
                        : '-'
                    return product
                }
            )
            // 附約
            const attachedProducts = this.dataList.insurance.attaches.map(
                (product) => {
                    product.productType = 'attached'
                    // TODO 後端把單位拆開後直接帶入就好

                    product.insAmount = product.insAmount
                        ? {
                              value: product.insAmount,
                              unit: product.insAmountUnit
                          }
                        : '-'

                    product.insPeriod = product.insPeriod
                        ? { value: product.insPeriod, unit: '年' }
                        : '-'
                    product.rfyp = {
                        value: product.rfyp,
                        unit: product.rfypUnit
                    }
                    product.bRate = {
                        value: product.bRate?.replace('%', ''),
                        unit: '%'
                    }
                    product.fyb = {
                        value: product.fyb,
                        unit: product.fybUnit
                    }
                    product.isVoid = product.isVoid
                        ? '是'
                        : product.isVoid === false
                        ? '否'
                        : '-'
                    return product
                }
            )

            return [...mainProduct, ...attachedProducts]
        },
        sumRow: function () {
            if (!this.dataList.insurance) return {}
            return {
                productName: '合計',
                RFYP: this.dataList.RFYP,
                BRate: this.dataList.avgBRate,
                FYB: this.dataList.FYB
            }
        },
        isShowEditModel: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        }
    },
    data() {
        return {
            columnConfig: insuranceProductColumns
        }
    }
}
</script>

<style lang="scss" scoped>
.viewtitle {
    display: flex;
    margin: 0 0 10px 0;
    color: $sixth-black;
    font-weight: 500;
    font-size: 18px;

    @media screen and (max-width: 576px) {
        font-size: 20px;
    }
}

.table-wrapper {
    :deep(.table) {
        max-height: none;
        &.loading {
            .table-body {
                .row-container:nth-of-type(3) ~ .row-container {
                    display: none;
                }
            }
        }
    }

    :deep(.card) {
        @media screen and (max-width: 576px) {
            .row-container .row.body {
                &.sum-row {
                    .product-type,
                    .is-void,
                    .ins-item,
                    .ins-amount,
                    .ins-period,
                    .b-rate {
                        display: none;
                    }
                    .r-f-y-p {
                        padding-top: 10px;
                        border-top: 1px dashed $fourth-grey;
                    }
                }
                .cell {
                    &.product-type {
                        order: -10;
                        margin-right: 5px;
                        width: 20px;
                        &:before {
                            display: none;
                        }
                        &:after {
                            content: '';
                        }
                    }
                    &.product-name {
                        order: -9;
                        width: calc(100% - 30px);
                        margin-right: 5px;
                        font-weight: 600;
                        &:before {
                            display: none;
                        }
                    }
                    //行動裝置版的保單商品開頭樣式
                    &.is-void {
                        padding-top: 10px;
                        border-top: 1px dashed $fourth-grey;
                    }
                }
            }
        }
    }
}

:deep(.main-product-icon),
:deep(.attached-product-icon) {
    width: 20px;
    height: 20px;
    border-radius: 3px;
}

.ins-content {
    :deep(.content-viewtitle) {
        font-weight: 500;
        .toggle-menu-icon {
            display: block;
        }
        @media screen and (min-width: 577px) {
            cursor: default;
            .toggle-menu-icon {
                display: none;
            }
        }
    }
}
</style>
